//= ==============================================================
// setViewport
//= ==============================================================
function setViewport() {
  const ua = navigator.userAgent;
  if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
    $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
  } else {
    $('head').prepend('<meta name="viewport" content="width=1440">');
  }
}

/*= ========================================================== */
/* Resize */
/*= ========================================================== */
function Resize() {
  const windowHeight = $(window).height();
  const finalHeight = `${windowHeight}px`;
  const windowWidth = $(window).width();
  const finalWindowWidth = `${windowWidth}px`;
  const headerHeight = $('#header').outerHeight();
  const finalHeaderHeight = `${headerHeight}px`;
  const fullminHead = windowHeight - headerHeight;
  const mobileShort = (windowHeight / 100) * 75;
  $('#content').css('width', finalWindowWidth);
  $('.dummy-header').css('height', finalHeaderHeight);
  $('.fullscreen').css('height', finalHeight);
  $('.min-fullscreen').css('min-height', finalHeight);
  $('.full-min-head').css('height', `${fullminHead}px`);
  if ($(window).width() > 520) {
    $('.fullscreen.shorter-mobile').css('height', finalHeight);
    $('.page-wrap#products-archive').css('padding-top', '0px');
  } else {
    $('.fullscreen.shorter-mobile').css('height', `${mobileShort}px`);
    $('.page-wrap#products-archive').css('padding-top', finalHeaderHeight);
  }
}

/*= ========================================================== */
/* Home */
/*= ========================================================== */
function Home() {
  $('.pg-top-slideWrap-body__item:first-child').addClass('is-visible');
  $('.pg-top-slideWrap-image__item').each(function () {
    const imagePanel = $(this);
    $(window).on('scroll', () => {
      const imageTargetOffset = imagePanel.offset().top;
      const imageIndex = imagePanel.attr('data-hook');
      if ($(window).scrollTop() > imageTargetOffset) {
        $('.pg-top-slideWrap-body__item').not(`#${imageIndex}`).removeClass('is-visible');
        $(`.pg-top-slideWrap-body__item#${imageIndex}`).addClass('is-visible');
      }
    });
  });
}

/*= ========================================================== */
/* pageTop */
/*= ========================================================== */
function pageTop() {
  // ページトップ
  const speed = 500;
  const btnTop = $('.c-pateTop');
  const btnTopTop = $('.c-pateTop ._top');
  btnTop.hide();

  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 300) {
      btnTop.fadeIn('fast');
    } else {
      btnTop.fadeOut('fast');
    }
    const scrollHeight = $(document).height(); // ドキュメントの高さ
    const scrollPosition = $(window).height() + $(window).scrollTop(); // 現在地
    const footHeight = $('footer').innerHeight(); // footerの高さ（＝止めたい位置）
  });
  btnTopTop.on('click', () => {
    $('body,html').animate({ scrollTop: '0' }, speed, 'swing');
    return false;
  });
}

/*= ========================================================== */
/* sliderpro */
/*= ========================================================== */
function slider() {
  $('#jsSlider01, #jsSlider02, #jsSlider03').sliderPro({
    arrows: true,
    buttons: true,
    width: 832,
    height: 832,
    slideDistance: 0,
    breakpoints: {
      767: {
        thumbnailWidth: '29.867vw',
        thumbnailHeight: '19.733vw',
      },
    },
  });
  $('#jsSliderGallery').sliderPro({
    forceSize: 'fullWidth',
    fullScreen: true,
    arrows: false,
    buttons: false,
    loop: true,
    autoplay: true,
    breakpoints: {
      767: {
        width: '100vw',
        height: '80vw',
      },
    },
  });
}

/*= ========================================================== */
/* Accordion */
/*= ========================================================== */
function Accordion() {
  $('.js-accordion').each(function () {
    const $ac = $(this);
    const $allBtn = $ac.find('.js-accordionBtn');
    const $allPanel = $ac.find('.js-additionPanel');
    $allPanel.hide();
    $allBtn.on('click', function () {
      const $btn = $(this);
      const $panel = $btn.next();
      $btn.toggleClass('is-active');
      $btn.next($panel).slideToggle(300);
    });
  });
}

/*= ========================================================== */
/* init */
/*= ========================================================== */
// ページがリサイズされたら動かしたい場合の記述
$(window).resize(() => {
  console.log('リサイズされた');
  Resize();
  slider();
});

// 画面をスクロールをしたら動かしたい場合の記述
$(window).on('scroll', function () {
  if ($('.l-header').height() < $(this).scrollTop()) {
    $('.l-header').addClass('is-active');
  } else {
    $('.l-header').removeClass('is-active');
  }
});

// ページが読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', () => {
  $('#splash-logo').delay(0).fadeOut('fast');// ロゴを1.2秒でフェードアウトする記述

  //= ====ここからローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる
  $('#splash').delay(0).fadeOut('fast', () => { // ローディングエリア（splashエリア）を1.5秒でフェードアウトする記述
    $('body').addClass('appear');// フェードアウト後bodyにappearクラス付与
  });
  //= ====ここまでローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる

  //= ====ここから背景が伸びた後に動かしたいJSをまとめたい場合は
  $('.splashbg1').on('animationend', () => {

  });
  //= ====ここまで背景が伸びた後に動かしたいJSをまとめる

  Resize();
  setViewport();
  slider();
  Accordion();
  Home();
  pageTop();
});

$(() => {
  $('.gallery-slider').slick({
    centerMode: true,
    centerPadding: '20%',
    autoplay: true,
    dot: false,
    arrows: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings:{
          slidesToShow: 1,
          centerPadding: 0,
        }
      },
    ],
  });

  $('.gallery-slider').on('beforeChange', () => {
    $('.slick-current').removeClass('is--active');
  });
  $('.gallery-slider').on('afterChange', () => {
    $('.slick-current').addClass('is--active');
  });
});
